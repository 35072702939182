<template>
	<div>
		<el-dialog :title="title" :visible="qrCodeIshow" width="500px" @close="comeback">
			<div v-loading="codeding">
				<el-input v-if="unlikeness" v-model="downloadData.titurl" style="width:300px" disabled></el-input>
				<el-input v-else v-model="downloadData.url" style="width:300px" disabled></el-input>
				<el-button v-clipboard:copy="unlikeness?downloadData.titurl:downloadData.url" v-clipboard:success="onCopy" v-clipboard:error="onError">
					复制
				</el-button>
				<!-- <img :src="imgUrl+codePicture" alt="" id="codeId"
					style="width:300px;display: block;margin: 30px auto 0"> -->
				<div style="width:250px;margin:30px auto 0px auto">
					<vue-qr v-if="isNeedExchangeToQRCode" :text="downloadData.url" :size="250" ref="Qrcode" :logoSrc="downloadData.icon"></vue-qr>
					<img v-else :src="downloadData.url" style="display:block;width:250px" />
				</div>
				<div style="text-align:center;margin-top: 25px;">
					<span style="color:#409EFF;cursor:pointer;padding:20px 0;display: inline-block; "
						@click="downloadIamge('img',downloadData.url)">{{downFont}}</span>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import apiList from '@/api/other';
	import config from '@/config/index'
	import vueQr from 'vue-qr'
	import {
		mallFreeVersionConfigInfo
	} from "@/api/goods"
	export default {
		components: {
			vueQr
		},
		props: {
			downloadData: {
				type: Object,
			},
			// weiCodeData: {
			// 	type: Object
			// },
			Num: {
				type: Number,
			},
			downFont: {
				type: String,
				default: '下载小程序码'
			},
			title: {
				type: String,
				default: () => {
					return '微信小程序'
				}
			},
			qrCodeIshow: {
				type: Boolean,
				default: false,
			},
			isNeedExchangeToQRCode: {
				type: Boolean,
				default: true,
			},
			unlikeness: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				goUrls: config.GO_URL,
				inputValue: '',
				codeding: false,
				InputvalueNew: '',
				mallConfigInfo: {},
			}
		},
		created() {},
		watch: {

		},
		methods: {

			comeback() {
				this.$emit('cancel', false)
			},

			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},

			downloadIamge(selector, name) {
				
				if (this.isNeedExchangeToQRCode){
					const iconUrl = this.$refs.Qrcode.$el.src
					let a = document.createElement('a')
					let event = new MouseEvent('click')
					a.download = this.downloadData.name
					a.href = iconUrl
					a.dispatchEvent(event)
				}else{
					this.downloadIamgeFromCanvas(selector, name)
				}
				
			},
			downloadIamgeFromCanvas(selector, name) {
				var image = new Image();
				var _this = this;
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height
			
					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')
			
					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')
			
					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = _this.downloadData.name || '下载图片名称'
					// 将生成的URL设置为a.href属性
					a.href = url;
					// 触发a的单击事件
					a.dispatchEvent(event)
				}
			
				image.src = this.downloadData.url
			
			
			},
		}
	}
</script>

<style lang="less">
	#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}
</style>
