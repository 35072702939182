<template>
	<div>

		<el-popover :trigger="trigger" width="200" placement="bottom">
			<div class="tips">扫描下方二维码体验</div>
			<div style="width:150px;margin:0 auto 0px" v-loading="codeloading">
				<!-- <vue-qr :text="downloadData.url" :size="150" ref="Qrcode" :logoSrc="downloadData.icon"></vue-qr> -->
				<img :src="downloadData.url" style="display: block;width: 150px;"></img>
			</div>
			<!-- {{downloadData.url}} -->
			<!-- <div style="text-align:center;">
				<span style="color:#409EFF;cursor:pointer;padding:20px 0;display: inline-block; "
					@click="downloadIamge('img',downloadData.url)">{{downFont}}</span>
			</div> -->
			<div style="text-align: center;">
				<el-link type="primary" :href="downloadData.link" target="_blank">→ 查看功能使用说明</el-link>
			</div>
			


			<el-button :type="buttontype" class="code-btn" :style="{'color':buttontype=='primary'?'white':'#666'}" slot="reference">
				<svg v-if="icon" t="1626336483888" class="icon" viewBox="0 0 1024 1024" version="1.1"
					xmlns="http://www.w3.org/2000/svg" p-id="2003" style="float:left" width="15" height="15">
					<path
						d="M357.6 960.8H254.8c-86.7 0-157.2-70.5-157.2-157.2V632c0-11 9-20 20-20s20 9 20 20v171.5c0 64.6 52.6 117.2 117.2 117.2h102.9c11 0 20 9 20 20s-9 20.1-20.1 20.1zM769.3 960.8H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c64.6 0 117.2-52.6 117.2-117.2V632c0-11 9-20 20-20s20 9 20 20v171.5c-0.1 86.7-70.6 157.3-157.2 157.3zM906.4 412c-11 0-20-9-20-20V220.5c0-64.6-52.6-117.2-117.2-117.2H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c86.7 0 157.2 70.5 157.2 157.2V392c-0.1 11-9 20-20.1 20zM117.6 412c-11 0-20-9-20-20V220.5c0-86.7 70.5-157.2 157.2-157.2h102.9c11 0 20 9 20 20s-9 20-20 20H254.8c-64.6 0-117.2 52.6-117.2 117.2V392c0 11-9 20-20 20zM957.9 532H66.1c-11 0-20-9-20-20s9-20 20-20h891.8c11 0 20 9 20 20s-9 20-20 20z"
						p-id="2004"></path>
				</svg>
				<div style="float:left;margin-left:5px;">功能体验码</div>
			</el-button>
		</el-popover>
	</div>
</template>

<script>
	import apiList from '@/api/other';
	import config from '@/config/index'
	import vueQr from 'vue-qr'
	import {
		mallFreeVersionConfigInfo
	} from "@/api/goods"
	export default {
		components: {
			vueQr
		},
		props: {
			downloadData: {
				type: Object,
				default: () => {
					return
				}
			},
			trigger:{
				type:String,
				default:'hover'
			},
			// weiCodeData: {
			// 	type: Object
			// },
			icon:{
				type: Boolean,
				default: true
			},
			buttontype:{
				type: String,
				default: 'text'
			},
			Num: {
				type: Number,
			},
			downFont: {
				type: String,
				default: '下载小程序码'
			},
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				goUrls: config.GO_URL,
				inputValue: '',
				codeding: false,
				InputvalueNew: '',
				mallConfigInfo: {},
				codeloading: false,
			}
		},
		created() {},
		watch: {

		},
		methods: {

			experience() {
				this.$emit('popoverShow')
				// setTimeout(()=>{
				//   console.log(this.downloadData)
				// },1000)
			},

			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},

			downloadIamge(selector, name) {
				const iconUrl = this.$refs.Qrcode.$el.src
				let a = document.createElement('a')
				let event = new MouseEvent('click')
				a.download = this.downloadData.name
				a.href = iconUrl
				a.dispatchEvent(event)
			},
		}
	}
</script>

<style lang="less">
	#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}
	
	.tips{
		font-size: 12px;
		color: #999;
		// margin-bottom: 20px;
		text-align: center;
	}
	
	.code-btn{
		color: #666;
		svg{
			fill: #666666;
		}
	}
	.code-btn:hover{
		svg{
			fill: #3a8ee6;
		}
	}
</style>
