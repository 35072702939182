<template>
	<div v-loading="pageloading">
		<!-- 只要没有安装企业微信助手就显示 -->
		<el-card v-if="!isauthorization">
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-b7dc93ee-4c84-4045-9d93-ee3f06fe4b35.png">
					<div class="info">
						<div class="tit">门店群活码</div>
						<div class="tip">使用该功能，为门店创建群活码并投放至各个门店，客户扫描活码直接进入门店群聊；还可以创建总码，投放至线上渠道，客户扫描活码后即可加入附近门店群聊。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="jump">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/功能说明</div>
				<img style="display: block;width:100%;max-width: 885px;"
					src="https://cdn.dkycn.cn/melyshop/image/20210802-81009ace-e3d1-4a0c-b1ef-c8fe37c5b738.png"></img>
				<div class="desc-title">2/客户入群</div>
				<img style="display: block;width:100%;max-width: 885px;"
					src="https://cdn.dkycn.cn/melyshop/image/20210803-a7f57c38-25de-4a00-8ee4-b66885427055.png"></img>
			</div>
		</el-card>
		<!-- 在安装了企业微信助手的情况下  判断总码配置是否为空 -->
		<div v-else-if="isauthorization && !totalsize">
			<el-card>
				<div>活码信息</div>
				<el-form :model="ruForm" :rules="rules" ref="ruform" style="margin-top:20px">
					<el-form-item prop="GroupLiveCodeName" label="活码名称:" label-width="100px">
						<el-input v-model="ruForm.GroupLiveCodeName" style="width:250px;" maxlength="15"
							placeholder="请输入活码名称,最多15个字"></el-input>
					</el-form-item>
					<el-form-item prop="CustomerId" label="加群客服:" label-width="100px">
						<el-select v-model="ruForm.CustomerId" style="width:250px;" filterable  placeholder="请选择员工">
							<el-option v-for="(v,i) in CustomerIdList" :key="i" :value="v.Id" :label="v.EmployeeName">
							</el-option>
						</el-select>
						<br />
						<div style="font-size:12px;color:rgb(180,180,180)">客户加群过程中遇到问题可联系客服</div>
					</el-form-item>
				</el-form>
			</el-card>
			<el-card style="position:fixed;bottom:0px;background:white;height:80px;width:100%;text-align:center">
				<el-button style="margin-left:-250px;width:90px;height:36px" @click="cancel"
					v-if="staging.IsUseHeadGroupLiveCode">取消</el-button>
				<el-button type="primary" style="width:90px;height:36px;" @click="save('ruform')">确定</el-button>
			</el-card>
		</div>
		<!-- 安装了企业微信助手并且总码不为空 -->
		<el-card v-else-if="isauthorization&&totalsize">
			<div style="display:flex;flex-direction:row">
				<div style="line-height:100px;font-size:14px">门店活码——总码:</div>
				<div style="width:100px;height:100px;margin:0px 10px">
					<vue-qr :text="totalCode.url" :size="100" :logoSrc="totalCode.icon"></vue-qr>
				</div>
				<el-button type="text" @click="downloadTotalCode">下载活码</el-button>
				<el-button type="text" @click="saveCode">活码设置</el-button>
			</div>
		</el-card>
		<el-card shadow="never" style="margin-top:10px" v-if="isauthorization&&totalsize">
			<div style="display:flex;flex-direction:row;flex-wrap:wrap;justify-content: space-between;">
				<div style="width:70%;display:flex;flex-direction:row;">
					<div style="font-size:14px;line-height:37px;color: #606266;font-weight: bold;">关键字:</div>
					<el-input v-model="keywords" style="width:250px;margin:0px 10px" placeholder="活码名称"></el-input>
					<el-button type="primary" @click="queryList">查询</el-button>
					<el-button type="primary" style="margin:0px 10px" @click="editCode()">添加活码</el-button>
				</div>
				<div>
					<qr-codepop :downloadData="experienceCode" :downFont="'下载群活码'"></qr-codepop>
					<!-- <el-button type="text" >
            <svg t="1626336483888" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2003" style="float:left" width="15" height="15">
            <path d="M357.6 960.8H254.8c-86.7 0-157.2-70.5-157.2-157.2V632c0-11 9-20 20-20s20 9 20 20v171.5c0 64.6 52.6 117.2 117.2 117.2h102.9c11 0 20 9 20 20s-9 20.1-20.1 20.1zM769.3 960.8H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c64.6 0 117.2-52.6 117.2-117.2V632c0-11 9-20 20-20s20 9 20 20v171.5c-0.1 86.7-70.6 157.3-157.2 157.3zM906.4 412c-11 0-20-9-20-20V220.5c0-64.6-52.6-117.2-117.2-117.2H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c86.7 0 157.2 70.5 157.2 157.2V392c-0.1 11-9 20-20.1 20zM117.6 412c-11 0-20-9-20-20V220.5c0-86.7 70.5-157.2 157.2-157.2h102.9c11 0 20 9 20 20s-9 20-20 20H254.8c-64.6 0-117.2 52.6-117.2 117.2V392c0 11-9 20-20 20zM957.9 532H66.1c-11 0-20-9-20-20s9-20 20-20h891.8c11 0 20 9 20 20s-9 20-20 20z" p-id="2004"></path></svg>
            
            <div style="float:left;margin-left:5px;color:black" @click="experience">功能体验码</div>
          </el-button> -->
				</div>
			</div>
			<!-- 表格 -->
			<div style="margin-top:10px">
				<el-table :data="tableList" v-loading="tableLoading">
					<el-table-column prop="" label="群活码">
						<template slot-scope="scope">
							<div style="display:flex;flex-direction:row">
								<!-- <div style="width:60px;height:60px;">
                  <img src="" style="width:100%;height:100%" alt="">
                </div> -->
								<div
									style="width:500px;font-size:14px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
									{{scope.row.GroupLiveCodeName}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ShopName" label="适用门店"></el-table-column>
					<el-table-column prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column prop="" label="操作" width="140px">
						<template slot-scope="scope">
							<el-button type="text" @click="editCode(scope.row)">编辑</el-button>
							<el-button type="text" @click="downloadCode(scope.row)">下载</el-button>
							<!-- <el-button type="text" style="color:rgb(245,108,108)" @click="deteleCode(scope.row)">删除 -->
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="text-align:right;margin-top:5px" v-if="total">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</el-card>

		<batch-cods :weiCodeVisivble="CodeShow" :weiCodeData="CodeData" @cancel="CodeCancel" :downFont="'下载群活码'"
			:title='"下载群活码"'></batch-cods>

		<qr-code :downloadData="downloadData" :qrCodeIshow="qrCodeIshow" :title="'群活码下载'" :downFont="'下载群活码'"
			@cancel="closeQrCode"></qr-code>
	</div>
</template>

<script>
	import {
		grouplivecodelist,
		grouplivecodedelete,
		grouplivecodeheadinfo,
		grouplivecodecustomerfilterBoxList,
		grouplivecodeheadsave,
		qyweixinsuiteauthinfo
	} from "@/api/sv1.0.0"
	import batchCods from '@/components/bathCodes';
	import config from "@/config/index";
	import vueQr from 'vue-qr'
	import QrCode from "@/components/bathQrCodes"
	import QrCodepop from "@/components/bathQrCodes2"
	import {
		mapGetters
	} from "vuex"
	export default {
		components: {
			batchCods,
			vueQr,
			QrCode,
			QrCodepop
		},
		computed: {
			...mapGetters(['pcImg'])
		},
		data() {
			return {
				isauthorization: true, //是否配置企业微信
				totalsize: true, //总码配置   为空时需要强行配置
				goUrls: config.GO_URL,
				ruForm: {
					Id: '',
					IsUseHeadGroupLiveCode: false,
					GroupLiveCodeName: '',
					CustomerId: '',
					GroupLiveCodeImgUrl: '',
					GroupLiveCodeImgUrlComplete: '',
				},
				staging: {},
				rules: {
					GroupLiveCodeName: [{
						required: true,
						message: '请输入活码名称',
						trigger: 'blur'
					}, ],
					CustomerId: [{
						required: true,
						message: '请选择加群客服',
						trigger: 'blur'
					}, ],
				},
				CustomerIdList: [], //客服列表
				keywords: '',
				tableList: [],
				tableLoading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,
				CodeShow: false,
				CodeData: {},
				pageloading: false,
				downloadData: {},
				qrCodeIshow: false,
				totalCode: {
					url: '',
					icon: this.pcImg,
					name: '',
				},
				qrCodeIshow2: false,
				experienceCode: {
					url: '',
					icon: this.pcImg,
					name: '',
				}
			}
		},
		created() {
			// console.log(this.pcImg)
			this.Isqyweixinsuiteauthinfo()

			let secondParam = window.localStorage.getItem('mlmzMallKey')
			let urlheader = ''
			if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
				urlheader = 'https://wap.dkyapi.cn'
			} else {
				urlheader = 'https://wap.dkyapitest.cn'
			}
			this.experienceCode = {
				// url: urlheader + '/custom/#/pages/shopCode/shopCode?gid=' + '1212' + '&mallkey=' + secondParam,
				url: 'http://cdn.dkycn.cn/images/melyshop/20210805034942.png',
				icon: this.pcImg,
				name: '功能体验码',
				link: 'https://jusnn6k8al.feishu.cn/docs/doccnJebsyYAyIx4kjovOnZnhSc'
			}
			// console.log(this.experienceCode)
		},
		methods: {
			closeQrCode(val) {
				// console.log(val)
				this.qrCodeIshow = false
			},
			jump() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#':'' 
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
			},
			//活码设置
			saveCode() {
				this.totalsize = false
			},
			//取消操作
			cancel() {
				this.ruForm = JSON.parse(JSON.stringify(this.staging))
				this.totalsize = true
			},
			//保存总码配置
			save(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// alert('submit!');
						this.togrouplivecodeheadsave()
					} else {
						this.$message.error('请完善配置信息')
						// console.log('error submit!!');
						return false;
					}
				});
			},
			async togrouplivecodeheadsave() {
				this.pageloading = true;
				try{
					let data = {
						GroupLiveCodeHeadInfoRequest: this.ruForm
					}
					let result = await grouplivecodeheadsave(data)
					if (result.IsSuccess) {
						this.$message.success('保存成功')
						this.getgrouplivecodeheadinfo()
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.pageloading = false
				}
			},
			//删除
			deteleCode(e) {
				// console.log(e)
				this.$confirm(`是否确认删除活码【${e.GroupLiveCodeName}】?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.togrouplivecodedelete(e)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
				// console.log(Id)

			},
			async togrouplivecodedelete(e) {
				this.pageloading = true
				
				try{
					let Id = await e
					let data = {
						Id: Id.GroupLiveCodeId
					}
					let result = await grouplivecodedelete(data)
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.$message.success('操作成功')
						this.getList()
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.pageloading = false
				}
				
				
			},
			//编辑
			editCode(e) {
				let Id = 0
				// console.log(e)
				if (e) {
					Id = e.GroupLiveCodeId
				}
				// console.log(e)
				let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
				let url =
					this.goUrls + headsUrls + "/Customer/storesLiveCode/changeLiveCode?Id=" + Id;
				window.open(url);
			},
			//下载群活码
			downloadCode(e) {
				let secondParam = window.localStorage.getItem('mlmzMallKey')

				let urlheader = ''
				if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
					urlheader = 'https://wap.dkyapi.cn'
				} else {
					urlheader = 'https://wap.dkyapitest.cn'
				}
				this.qrCodeIshow = true
				this.downloadData = {
					url: urlheader + '/custom/#/pages/shopCode/shopCode?gid=' + e.GroupLiveCodeGuid +
						'&type=2&mallkey=' + secondParam,
					icon: this.pcImg,
					name: e.GroupLiveCodeName,
					link: 'https://jusnn6k8al.feishu.cn/docs/doccnJebsyYAyIx4kjovOnZnhSc'
				}
			},
			//下载总活码
			downloadTotalCode() {

				this.qrCodeIshow = true
				this.downloadData = this.totalCode
				// console.log(this.downloadData)
			},
			CodeCancel(value) {
				this.CodeShow = value;
			},

			//页码 页数
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.getList()
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.getList()
			},
			queryList() {
				this.currentPage = 1
				this.getList()
			},
			//获取客服列表
			async getcustomerfilterBoxList() {
				let result = await grouplivecodecustomerfilterBoxList({})
				if (result.IsSuccess) {
					// console.log(result.Result,'客服')
					this.CustomerIdList = result.Result;
					
					var list = result.Result.filter(item=>{
						return item.Id === this.ruForm.CustomerId
					})
					
					if (!list.length){
						this.ruForm.CustomerId = ''
					}
				}
			},
			//初始化
			async getgrouplivecodeheadinfo() {
				this.pageloading = true;
				
				try{
					let result = await grouplivecodeheadinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.staging = JSON.parse(JSON.stringify(result.Result))
						for (var item in result.Result){
							this.ruForm[item] = result.Result[item]
						}
						
						this.totalsize = result.Result.IsUseHeadGroupLiveCode
						if (result.Result.IsUseHeadGroupLiveCode) {
							this.getList()

							let secondParam = window.localStorage.getItem('mlmzMallKey')
							let urlheader = ''
							if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
								urlheader = 'https://wap.dkyapi.cn'
							} else {
								urlheader = 'https://wap.dkyapitest.cn'
							}
							this.totalCode = {
								url: urlheader + '/custom/#/pages/shopCode/shopCode?gid=' + result.Result
									.GroupLiveCodeGuid + '&type=0&mallkey=' + secondParam,
								icon: this.pcImg,
								name: result.Result.GroupLiveCodeName,
								link: 'https://jusnn6k8al.feishu.cn/docs/doccnJebsyYAyIx4kjovOnZnhSc'
							}
							// console.log(this.totalCode)
						}
					}
					
					this.getcustomerfilterBoxList();
				
				}catch(e){
					//TODO handle the exception
				}finally{
					this.pageloading = false
				}
				
			},
			//初始化  是否配置总码

			//列表
			async getList() {
				this.tableLoading = true;
				
				try{
					let data = {
						Keywords: this.keywords,
						GroupLiveCodeType: 2,
						Skip: (this.currentPage - 1) * this.sizepage,
						Take: this.sizepage
					}
					let result = await grouplivecodelist(data)
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.tableList = result.Result.Results
						this.total = result.Result.Total
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.tableLoading = false
				}
				
			},

			// this.getgrouplivecodeheadinfo()
			// this.getcustomerfilterBoxList()

			//查看是否配置企业微信
			async Isqyweixinsuiteauthinfo() {
				this.pageloading = true
				try {
					let result = await qyweixinsuiteauthinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.isauthorization = result.Result.IsFinishQyWeixinSuiteStp
						if (this.isauthorization) {
							this.getgrouplivecodeheadinfo()
							
						}
					}
				} finally {
					this.pageloading = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.a {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
